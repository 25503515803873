<template>
  <div>
    <MoreMenuButton v-if="groupOptions" :groups="groupOptions"></MoreMenuButton>
    <GeneratePatientCardDialog
      v-if="showMoreDialog"
      :mode="mode"
      :session="sessionGetter()"
      :patient="patient"
      :value.sync="showMoreDialog"
      :owner="owner"
      :forceAllDoctors="!canUsePatientCards && !isSpectatorMode"
      @action="onAction"
      @success="handleSuccess"
      @error="handleError"
    ></GeneratePatientCardDialog>
    <RecommendationsDialog
      v-if="showRecommendationsDialog"
      :mode="mode"
      :value.sync="showRecommendationsDialog"
      :session="sessionGetter()"
      :owner="owner"
      :patient="patient"
      @action="onAction"
      @success="handleSuccess"
      @error="handleError"
    ></RecommendationsDialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import printJS from "print-js";
import PatientService from "@/services/patient.service";
import ProxyCRUDMixin from "@/mixins/ProxyCRUDMixin";

const DOWNLOAD = "DOWNLOAD";
const PRINT = "PRINT";

const CARD = "CARD";
const RECOMMENDATIONS = "RECOMMENDATIONS";

export default {
  mixins: [ProxyCRUDMixin],
  props: {
    patient: {},
    sessionGetter: {
      type: Function,
      default: () => {},
    },
    owner: {},
  },
  data() {
    return {
      option: CARD,
      mode: DOWNLOAD,
      showMoreDialog: false,
      showRecommendationsDialog: false,
      generateTimetablePopupVisible: false,
    };
  },
  components: {
    MoreMenuButton: () => import("@/components/menus/MoreMenuButton"),
    GeneratePatientCardDialog: () =>
      import("@/components/popups/patientcard/GeneratePatientCardDialog"),
    RecommendationsDialog: () =>
      import("@/components/popups/patientcard/RecommendationsDialog"),
  },
  computed: { 
    ...mapGetters("ui", [
      "canUsePatientCards",
    ]),
    patientName() { 
      if (this.patient.name) {
        return this.patient.name;
      } else {
        return `${this.patient.first_name} ${this.patient.last_name}`
      }
    },
    isSpectatorMode() {
      return !!this.owner;
    },
    groupOptions() {
      const initialOptions = [
        {
          title: "Karta pacjenta:",
          items: [
            {
              title: "Pobierz",
              action: () => this.cardMore(DOWNLOAD),
            },
            {
              title: "Wydrukuj",
              action: () => this.cardMore(PRINT),
            },
          ],
        }
      ];
      if (this.canUsePatientCards || this.isSpectatorMode) {
        initialOptions.push({
          title: "Zalecenia:",
          items: [
            {
              title: "Pobierz",
              action: () => this.recommendationsMore(DOWNLOAD),
            },
            {
              title: "Drukuj",
              action: () => this.recommendationsMore(PRINT),
            },
          ],
        });
      }
      return initialOptions;
    }
  },
  methods: {
    cardMore(mode) {
      this.mode = mode;
      this.option = CARD;
      this.showMoreDialog = true;
    },
    recommendationsMore(mode) {
      this.mode = mode;
      this.option = RECOMMENDATIONS;
      this.showRecommendationsDialog = true;
    },
    onAction(url) {
      switch (this.mode) {
        case PRINT:
          this.printAction(url);
          break;
        case DOWNLOAD:
          this.downloadAction(url);
          break;
        default:
          return;
      }
    },
    printAction(url) {
      this.beforeRequest();
      PatientService.downloadDocument(
        `${process.env.VUE_APP_URL_BACKEND}/media/${url}`
      )
        .then((x) => {
          const url = window.URL.createObjectURL(new Blob([x.data], {
            type: "application/pdf"
          }));
          this.reset();
          try {
            printJS(url);
          } catch {
            alert(
              "Twoja przeglądarka nie zezwala na drukowanie ze strony. Pobierz plik i wydrukuj ze swojego urządzenia."
            );
          }
        })
        .catch((x) => {
          console.log("ERROR", x);
          this.handleError("Coś poszło nie tak");
        });
    },
    safeFilename() {
      const fileType = this.option === CARD ? "Karta_Pacjenta" : "Zalecenia";
      let filename = `${fileType}_${this.patientName}.pdf`
      filename = filename.normalize();
      return encodeURI(filename);
    },
    downloadAction(url) {
      this.beforeRequest();
      PatientService.downloadDocument(
        `${process.env.VUE_APP_URL_BACKEND}/media/${url}`
      )
        .then((x) => {
          const url = window.URL.createObjectURL(
            new Blob([x.data], {
              type: "application/pdf",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", this.safeFilename());
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          this.reset();
        })
        .catch((x) => {
          console.log("ERROR", x);
          this.handleError("Coś poszło nie tak");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.popups-style {
  z-index: 2 !important;
}
</style>